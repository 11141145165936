import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {userService} from '../../services';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import {useHistory} from 'react-router-dom';
const {login, saveAccessToken} = userService;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 50,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
  }),
);
export const LoginPage = (props) => {
  const [submitingData, updateSubmittingData] = useState(false);
  const [username, updateUsername] = useState('');
  const [password, updatePassword] = useState('');
  const [error, updateError] = useState('');
  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    updateSubmittingData(true);
    try {
      const res = await login({username, password});
      /* eslint camelcase: ["error", {ignoreDestructuring: true}]*/
      const {access_token} = res.data;
      saveAccessToken(access_token);
      history.push('/');
      return;
    } catch (err) {
      updateError(err.data.message);
    }
    updateSubmittingData(false);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <div className="form" style={{width: '100%'}}>
            <div style={{margin: 20}}>
              <h2 className="center heading-block">Vdocipher</h2>
              <div className="account-heading">
                Sign in with your Vdocipher account
              </div>
              {error && <p>{error}</p>}
            </div>
            <form onSubmit={(e) => submit(e)}>
              <div style={{margin: 20}}>
                <label htmlFor="username">Username</label>
                <Input
                  id="username"
                  value={username}
                  name="username"
                  placeholder="example@vdocipher.com"
                  fullWidth
                  type="email"
                  onChange={(e) => updateUsername(e.target.value)}
                  required
                />
              </div>
              <div style={{margin: 20}}>
                <label htmlFor="password">Password</label>
                <Input
                  id="password"
                  value={password}
                  name="password"
                  type="password"
                  placeholder="***********"
                  fullWidth
                  onChange={(e) => updatePassword(e.target.value)}
                  required
                />
              </div>
              <div style={{margin: 20}}>
                <Button
                  disabled={submitingData}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        </Grid>
      </Paper>
    </div>
  );
};
