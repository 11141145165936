import React, {useState} from 'react';
import {Button, Checkbox, TextField} from '@material-ui/core';

import {useApi, headerWithAuth} from '../../../../utils/api/hook';

const changeEmail = ({oldEmail, newEmail, confirm = false}) =>
  fetch('/api/cms/scripts/email-change', {
    method: 'POST',
    headers: {
      ...headerWithAuth(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({oldEmail, newEmail, confirm}),
  });

export const EmailChange = () => {
  const [oldEmail, setOldEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirm, setConfirm] = useState(false);
  const {data, loading, error, status, call} = useApi(changeEmail);
  const handleFormSubmit = (e) => {
    call({oldEmail, newEmail, confirm}).then(() => setConfirm(false));
    e.preventDefault();
  };
  return (
    <div>
      <h2>Email change</h2>
      <form autoComplete={'off'} onSubmit={handleFormSubmit}>
        <div style={{marginBottom: 10}}>
          <TextField
            required
            style={{width: 500}}
            label="Old Email"
            value={oldEmail}
            type={'email'}
            onChange={({target}) => setOldEmail(target.value)}
          />
        </div>
        <div style={{marginBottom: 10}}>
          <TextField
            required
            style={{width: 500}}
            label="New Email"
            type={'email'}
            value={newEmail}
            onChange={({target}) => setNewEmail(target.value)}
          />
        </div>
        {loading && <p>Loading...</p>}
        {data && data.message && <p>Response: {data.message}</p>}
        {error && error.result && error.result.message && (
          <p style={{color: 'red'}}>{error.result.message}</p>
        )}
        {status === 400 &&
          error &&
          error.result &&
          error.result.message &&
          error.result.message.match(/Confirm/) && (
            <p>
              Confirm?{' '}
              <Checkbox
                onChange={(e) => setConfirm(e.target.checked)}
                checked={confirm}
              />
            </p>
          )}
        <Button
          variant={'contained'}
          color={'primary'}
          disabled={loading || !oldEmail || !newEmail}
          type={'submit'}
        >
          Change
        </Button>
      </form>
    </div>
  );
};
