import React from 'react';
import Dashboard from './dashboard';
import {useHistory} from 'react-router-dom';

export const HomePage = () => {
  const history = useHistory();
  if (localStorage.getItem('accessToken') === null) {
    history.push('/login');
    return null;
  }
  return <Dashboard />;
};
