import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {formatBytes} from '../../../../helpers/format-bytes';
import {usePlansStateHook} from './stateHook';

const Plans = (props) => {
  const {
    state,
    onChange,
    saveForm,
    updateOpenCreateForm,
    changeDate,
  } = usePlansStateHook();
  const {
    newPlanName,
    newPlanStorage,
    newPlanValidity,
    newPlanAmount,
    newPlanBandwidth,
    validityLogic,
    date,
    fetchingList,
    list,
    error,
    openCreateForm,
    savingForm,
  } = state;

  if (fetchingList) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={100} />
      </div>
    );
  }
  return (
    <div>
      {error && <p>{error}</p>}
      <Button
        color="primary"
        variant="contained"
        onClick={() => updateOpenCreateForm(true)}
      >
        Create Plan
      </Button>
      {openCreateForm && (
        <form onSubmit={(e) => saveForm(e)}>
          <div>
            <TextField
              label="Plan Name"
              required
              value={newPlanName}
              name="newPlanName"
              onChange={onChange}
            />
          </div>
          <div>
            <TextField
              label="Storage (GB)"
              value={newPlanStorage}
              type={'number'}
              name="newPlanStorage"
              onChange={onChange}
            />
          </div>
          <div>
            <TextField
              label="Bandwidth (GB)"
              type={'number'}
              value={newPlanBandwidth}
              name="newPlanBandwidth"
              onChange={onChange}
            />
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              name="validityLogic"
              value={validityLogic}
              onChange={onChange}
            >
              <FormControlLabel
                value="extend-from-recharge-date"
                control={<Radio />}
                label="extend From Recharge Date"
              />
              <FormControlLabel
                value="extend-from-current-plan-validity"
                control={<Radio />}
                label="extend-from-current-plan-validity"
              />
              <FormControlLabel
                value="extend-by-date"
                control={<Radio />}
                label="Extend By Date"
              />
              <FormControlLabel
                value="keep-current-validity"
                control={<Radio />}
                label="Keep current validity"
              />
            </RadioGroup>
          </FormControl>
          {validityLogic === 'extend-by-date' ? (
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  format="MM/dd/yyyy"
                  value={date}
                  name="date"
                  onChange={(x) => changeDate(x)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          ) : (
            <div>
              <TextField
                label="Validity (in days)"
                type={'number'}
                value={newPlanValidity}
                required
                name="newPlanValidity"
                onChange={onChange}
              />
            </div>
          )}
          {/*
          <FormControlLabel
            control={
              <Checkbox
                checked={isValidityExtended}
                onChange={(e) => updateIsValidityExtended(!isValidityExtended)}
                name="isValidityExtended"
                color="primary"
              />
            }
            label="Is Validity Extended?"
          />
          */}
          <div>
            <TextField
              label="Amount (in USD)"
              type="number"
              value={newPlanAmount}
              required
              name="newPlanAmount"
              onChange={onChange}
            />
          </div>
          <Button
            style={{marginTop: 10, marginRight: 10}}
            disabled={savingForm}
            color="primary"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
          <Button
            style={{marginTop: 10, marginRight: 10}}
            color="secondary"
            onClick={() => updateOpenCreateForm(false)}
            variant="contained"
          >
            Cancel
          </Button>
        </form>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                Id <br />
                <small>(Click to copy)</small>
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Bandwidth</TableCell>
              <TableCell>Storage</TableCell>
              <TableCell>Validity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Validity Logic</TableCell>
              <TableCell>Extend Till Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Button
                    onClick={(e) =>
                      navigator.clipboard.writeText(e.target.innerText)
                    }
                    variant="contained"
                  >
                    {item.id}
                  </Button>
                </TableCell>
                <TableCell>{item.codename}</TableCell>
                <TableCell>{formatBytes(item.bandwidth * 1024)}</TableCell>
                <TableCell>{formatBytes(item.storage * 1024 * 1024)}</TableCell>
                <TableCell>{item.validity} days</TableCell>
                <TableCell>${item.amount_usd}</TableCell>
                <TableCell>{item.validityLogic}</TableCell>
                <TableCell>
                  {item.extendTillDate && new Date(item.extendTillDate * 1000)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Plans;
