import React, {Component} from 'react';
import {
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Input,
} from '@material-ui/core';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
const {countries} = require('../../../../../helpers/countries');

class BillingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstin: '',
      billingName: '',
      address: '',
      country: '',
      billingPhone: '',
      extraBillingInfo: '',
      planName: '',
      paymentToken: '',
      paymentMethod: 'OFFLINE',
      storage: 0,
      bandwidth: 0,
      daysTillValid: 0,
      amount: 0,
      existingInvoiceId: '',
    };
  }
  componentDidMount() {
    const {userSettings} = this.props;
    this.setState({
      country: userSettings.billingCountry || 'XX',
      gstin: userSettings.gstin || '',
      billingName: userSettings.billingName || '',
      address: userSettings.billingAddress || '',
      billingPhone: userSettings.billingPhone || '',
      extraBillingInfo: userSettings.extraBillingInfo || '',
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  submitRechargeForm(e) {
    e.preventDefault();
    let {
      gstin,
      billingName,
      address,
      country,
      billingPhone,
      extraBillingInfo,
      planName,
      paymentMethod,
      storage,
      bandwidth,
      daysTillValid,
      paymentToken,
      amount,
      existingInvoiceId,
    } = this.state;
    if (this.state.country !== 'IN') {
      gstin = '';
    }

    this.props.saveRechargeFormDetails({
      gstin,
      billingName,
      address,
      country,
      billingPhone,
      extraBillingInfo,
      planName,
      paymentMethod,
      paymentToken,
      storage,
      bandwidth,
      daysTillValid,
      amount,
      existingInvoiceId,
    });
  }
  render() {
    const {
      country,
      gstin,
      billingName,
      address,
      billingPhone,
      extraBillingInfo,
      planName,
      paymentMethod,
      paymentToken,
      storage,
      bandwidth,
      daysTillValid,
      amount,
      existingInvoiceId,
    } = this.state;
    const {project, onClose} = this.props;
    return (
      <div style={{padding: 20}}>
        {`Recharge account of Email ${project.email} with user id ${project.id}`}
        <form onSubmit={(e) => this.submitRechargeForm(e)}>
          <div>
            <TextField
              name="planName"
              value={planName}
              label="Plan Name (Starter, Value, Express, Custom)"
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div>
            <TextField
              name="existingInvoiceId"
              value={existingInvoiceId}
              label="Invoice ID (Leave it empty if wants to auto generate)"
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div>
            <TextField
              name="bandwidth"
              label="Bandwidth in GB"
              value={bandwidth}
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div>
            <TextField
              name="amount"
              label="Amount in USD"
              value={amount}
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div>
            <TextField
              name="storage"
              value={storage}
              label="Storage in GB"
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div>
            <TextField
              name="daysTillValid"
              value={daysTillValid}
              label="No of Days"
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <FormControl fullWidth={true} required>
            <InputLabel>Country of Billing</InputLabel>
            <Select
              value={country}
              onChange={(e) => this.handleChange(e)}
              input={<Input name="country" />}
            >
              {Object.keys(countries).map((code) => (
                <MenuItem key={code} value={code}>
                  {countries[code]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {country === 'IN' && (
            <div>
              <TextField
                name="gstin"
                value={gstin}
                label="GSTIN"
                margin="normal"
                onChange={(e) => this.handleChange(e)}
                inputProps={{minLength: '15', maxLength: '15'}}
              />
            </div>
          )}
          <div>
            Total Amount ={' '}
            {country === 'IN' ? Math.floor(amount * 1.18 * 100) / 100 : amount}
          </div>
          <div>
            <TextField
              name="billingName"
              value={billingName}
              label="Billing name"
              margin="normal"
              fullWidth
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div style={{marginTop: 16}}>
            <PhoneInput
              placeholder="Enter phone number"
              value={billingPhone}
              country={country}
              onChange={(billingPhone) => this.setState({billingPhone})}
              required
            />
          </div>
          <div>
            <TextField
              name="address"
              value={address}
              label="Billing address"
              margin="normal"
              multiline
              fullWidth
              rowsMax="4"
              rows={2}
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div>
            <TextField
              name="extraBillingInfo"
              value={extraBillingInfo}
              label="Additional Info to show in invoice (e.g Tax ID)"
              margin="normal"
              multiline
              fullWidth
              rowsMax="4"
              rows={2}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div>
            <TextField
              name="paymentToken"
              value={paymentToken}
              label="Paymnet Token"
              margin="normal"
              onChange={(e) => this.handleChange(e)}
            />
          </div>

          <FormControl fullWidth={true} required>
            <InputLabel>Payment Method of Billing</InputLabel>
            <Select
              value={paymentMethod}
              onChange={(e) => this.handleChange(e)}
              input={<Input name="paymentMethod" />}
            >
              <MenuItem value={'OFFLINE'}>OFFLINE</MenuItem>
              <MenuItem value={'PAYPAL'}>PAYPAL</MenuItem>
              <MenuItem value={'RAZORPAY'}>RAZORPAY</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            style={{float: 'right'}}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{float: 'right'}}
          >
            Continue
          </Button>
        </form>
      </div>
    );
  }
}

export default BillingInfo;
