import {transactionsConstants} from '../constants';

const initialState = {
  errorMessage: null,
  loading: false,
  transactionList: [],
};
const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionsConstants.FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case transactionsConstants.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionList: action.transactionList,
        loading: false,
      };
    case transactionsConstants.TRANSACTIONS_FAILED: {
      const {transactionList} = state;
      const index = transactionList.findIndex(
        (f) => f.transaction === action.transactionId,
      );
      const newState = {
        ...state,
        errorMessage: action.message,
        loading: false,
        transactionList: [
          ...transactionList.slice(0, index),
          ...transactionList.slice(index + 1, transactionList.length),
        ],
      };
      return newState;
    }
    case transactionsConstants.TRANSACTIONS_SUCCESSFULL: {
      const {transactionList} = state;
      const index = transactionList.findIndex(
        (f) => f.transaction === action.transactionId,
      );
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
        transactionList: [
          ...transactionList.slice(0, index),
          ...transactionList.slice(index + 1, transactionList.length),
        ],
      };
    }
    default:
      return state;
  }
};

export default transactionsReducer;
