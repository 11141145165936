import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {transactionsServices} from '../../../../../services';
import Button from '@material-ui/core/Button';
import BillingInfo from './billing-info';
import TransactionTable from '../../transactions/transaction-table';
import {Paper} from '@material-ui/core';
const RechargeUser = ({project, userSettings}) => {
  const initialState = {
    fetchingTransactionList: false,
    creatingTransactionList: false,
    transactionList: [],
    isCreateRechargeFormOpen: false,
    newRecharge: {},
    creatingRechargeError: null,
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    async function fetchTransactions() {
      try {
        setState((prevState) => ({
          ...prevState,
          fetchingTransactionList: false,
        }));
        const res = await transactionsServices.fetchTransactionsAPI({
          userId: project.id,
        });
        const {transactions} = res.data;
        setState((prevState) => ({
          ...prevState,
          fetchingTransactionList: false,
          transactionList: transactions || [],
        }));
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          fetchingTransactionList: false,
        }));
      }
    }
    fetchTransactions();
  }, []);
  const {
    transactionList,
    isCreateRechargeFormOpen,
    creatingRechargeError,
    fetchingTransactionList,
  } = state;
  if (fetchingTransactionList) {
    return <div>loading....</div>;
  }
  const openForm = (status) => {
    setState((prevState) => ({
      ...prevState,
      isCreateRechargeFormOpen: status,
    }));
  };
  const saveRechargeFormDetails = async (rechargeInfo) => {
    try {
      setState((prevState) => ({
        ...prevState,
        creatingRecharge: true,
      }));
      await transactionsServices.createNewRechargeAPI({
        ...rechargeInfo,
        userId: project.id,
      });
      setState((prevState) => ({
        ...prevState,
        creatingRecharge: false,
        creatingRechargeError: null,
        isCreateRechargeFormOpen: false,
      }));
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        creatingRechargeError: e.data,
        creatingRecharge: false,
      }));
    }
  };
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => openForm(true)}
      >
        Create New Recharge
      </Button>
      <Dialog open={isCreateRechargeFormOpen} onClose={() => openForm(false)}>
        <DialogContent style={{width: 500}}>
          {creatingRechargeError && <p>{creatingRechargeError}</p>}
          <BillingInfo
            onClose={() => openForm(false)}
            project={project}
            userSettings={userSettings}
            saveRechargeFormDetails={saveRechargeFormDetails}
          />
        </DialogContent>
      </Dialog>
      <Paper style={{overflow: 'auto'}}>
        <TransactionTable transactions={transactionList} />
      </Paper>
    </div>
  );
};

export default RechargeUser;
