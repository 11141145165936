import {blogsConstants} from '../constants';
import {blogsServices} from '../services';

const fetchPages = (data) => {
  const request = () => ({type: blogsConstants.FETCH_PAGES_REQUEST});
  const success = (pages) => ({
    type: blogsConstants.FETCH_PAGES_SUCCESS,
    pages,
  });
  const failure = (message) => ({
    type: blogsConstants.FETCH_PAGES_FAILURE,
    message,
  });
  return (dispatch) => {
    dispatch(request());

    return blogsServices
      .fetchPages()
      .then((res) => {
        const {rows} = res.data;
        return dispatch(success(rows));
      })
      .catch((error) => {
        return dispatch(failure(error.data));
      });
  };
};

const fetchPage = (id) => {
  const request = () => ({type: blogsConstants.FETCH_PAGE_REQUEST});
  const success = (page) => ({type: blogsConstants.FETCH_PAGE_SUCCESS, page});
  const failure = (message) => ({
    type: blogsConstants.FETCH_PAGE_FAILURE,
    message,
  });
  return (dispatch) => {
    dispatch(request());

    return blogsServices
      .fetchPage(id)
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((error) => {
        dispatch(failure(error.data));
      });
  };
};

const createPage = (data) => {
  const request = () => ({type: blogsConstants.CREATE_PAGE_REQUEST});
  const success = (page) => ({type: blogsConstants.CREATE_PAGE_SUCCESS, page});
  const failure = (message) => ({
    type: blogsConstants.CREATE_PAGE_FAILURE,
    message,
  });
  return (dispatch) => {
    dispatch(request());

    return blogsServices
      .createPage(data)
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((error) => {
        dispatch(failure(error.data));
      });
  };
};

const updatePage = (data) => {
  const request = () => ({type: blogsConstants.UPDATE_PAGE_REQUEST});
  const success = (page) => ({type: blogsConstants.UPDATE_PAGE_SUCCESS, page});
  const failure = (message) => ({
    type: blogsConstants.UPDATE_PAGE_FAILURE,
    message,
  });
  return (dispatch) => {
    dispatch(request());

    return blogsServices
      .updatePage(data)
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((error) => {
        dispatch(failure(error.data));
      });
  };
};

const deletePage = (id) => {
  const request = () => ({type: blogsConstants.DELETE_PAGE_REQUEST});
  const success = (message) => ({
    type: blogsConstants.DELETE_PAGE_SUCCESS,
    message,
  });
  const failure = (message) => ({
    type: blogsConstants.DELETE_PAGE_FAILURE,
    message,
  });
  return (dispatch) => {
    dispatch(request());

    return blogsServices
      .deletePage(id)
      .then((res) => {
        dispatch(success(res.data));
      })
      .catch((error) => {
        dispatch(failure(error.data));
      });
  };
};

export const blogsActions = {
  fetchPages,
  fetchPage,
  createPage,
  updatePage,
  deletePage,
};
