import {userListConstants} from '../constants';

const initialState = {
  errorMessage: null,
  loading: false,
  userList: [],
  userSearchText: '',
  searchingUsers: false,
};
const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userListConstants.FETCH_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.userList,
        loading: false,
      };
    case userListConstants.FETCH_USER_LIST_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };
    case userListConstants.CHANGE_USER_SEARCH_TEXT:
      return {
        ...state,
        userSearchText: action.userSearchText,
      };
    case userListConstants.SEARCH_USER_LIST_REQUEST:
      return {
        ...state,
        searchingUsers: true,
      };
    case userListConstants.SEARCH_USER_LIST_SUCCESS:
      return {
        ...state,
        searchingUsers: false,
        userList: action.userList,
        userSearchText: '',
      };
    case userListConstants.SEARCH_USER_LIST_FAILURE:
      return {
        ...state,
        searchingUsers: false,
      };
    default:
      return state;
  }
};

export default userListReducer;
