export const projectsConstants = {
  FETCH_PROJECTS_REQUEST: 'FETCH_PROJECTS_REQUEST',
  FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
  FETCH_PROJECTS_FAILURE: 'FETCH_PROJECTS_FAILURE',
  SHOW_CURRENT_PROJECT_SETTINGS: 'SHOW_CURRENT_PROJECT_SETTINGS',
  FETCH_PROJECTS_SETTINGS_FAILURE: 'FETCH_PROJECTS_SETTINGS_FAILURE',
  FETCH_PROJECT_SETTINGS_SUCCESS: 'FETCH_PROJECT_SETTINGS_SUCCESS',
  FETCH_PROJECTS_SETTINGS_REQUEST: 'FETCH_PROJECTS_SETTINGS_REQUEST',
  SETTING_INPUT_VALUE_CHANGE: 'SETTING_INPUT_VALUE_CHANGE',
  UPDATE_PROJECT_SETTING_REQUEST: 'UPDATE_PROJECT_SETTING_REQUEST',
  UPDATE_PROJECT_SETTING_SUCCESS: 'UPDATE_PROJECT_SETTING_SUCCESS',
  UPDATE_PROJECT_SETTING_FAILURE: 'UPDATE_PROJECT_SETTING_FAILURE',
  DELETE_PROJECT_SETTING_REQUEST: 'DELETE_PROJECT_SETTING_REQUEST',
  DELETE_PROJECT_SETTING_SUCCESS: 'DELETE_PROJECT_SETTING_SUCCESS',
  DELETE_PROJECT_SETTING_FAILURE: 'DELETE_PROJECT_SETTING_FAILURE',
  CHANGE_TAB_VALUE: 'CHANGE_TAB_VALUE',
};
