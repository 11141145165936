import React, {Component} from 'react';

import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/github';
import 'brace/ext/searchbox';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {blogsActions} from '../../../../actions';
import {EditorHeader} from './editor-header';

const {fetchPage, updatePage, createPage, deletePage} = blogsActions;

class AceEditorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      body: '',
      metaTags: [],
      slug: '',
      uuid: '',
      permission: 0,
    };
  }
  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }
  handleEditorChange(newValue) {
    this.setState({
      body: newValue,
    });
  }
  componentDidMount() {
    const {match} = this.props;
    const {params} = match;
    const {slug} = params;
    if (slug) {
      this.props.fetchPage(slug);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.page && this.props.page !== prevProps.page) {
      const {title, body, metaTags, slug, uuid} = this.props.page;
      this.setState({title, body, metaTags, slug, uuid});
    }
  }

  onCreate(e) {
    e.preventDefault();
    const {title, body, slug, metaTags, permission} = this.state;
    this.props.createPage({title, body, slug, metaTags, permission});
  }
  onUpdate() {
    const {uuid, title, body, slug, metaTags, permission} = this.state;
    this.props.updatePage({uuid, title, body, slug, metaTags, permission});
  }
  onDelete() {
    const {uuid} = this.state;
    this.props.deletePage(uuid);
  }

  render() {
    const {body} = this.state;
    const {match} = this.props;
    const {params} = match;
    if (params.slug && !this.state.slug) {
      return <div>loading...</div>;
    }
    return (
      <div>
        <EditorHeader
          onChange={this.onChange.bind(this)}
          parentState={this.state}
          parentProps={this.props}
          onCreate={this.onCreate.bind(this)}
          onUpdate={this.onUpdate.bind(this)}
          onDelete={this.onDelete.bind(this)}
        />
        <div>
          <AceEditor
            mode="html"
            theme="github"
            editorProps={{$blockScrolling: true}}
            width={'100%'}
            height={window.innerHeight - 150 + 'px'}
            wrapEnabled={true}
            value={body}
            onChange={this.handleEditorChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {blogs} = state;
  return blogs;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {fetchPage, updatePage, createPage, deletePage},
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AceEditorForm);
