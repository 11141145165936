/* global process */
import AxiosAPI from './axios-api';
const env = () => {
  return process.env.NODE_ENV;
};

export const configFile = require('../config/' + env() + '.json');

const baseHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const responseInterceptors = {
  success(res) {
    return res;
  },
  failure(err) {
    let errorObj = err.response;
    if (typeof errorObj !== 'undefined' && err.response.status === 401) {
      localStorage.removeItem('accessToken');
    }
    if (typeof errorObj === 'undefined') {
      errorObj = {
        status: 0,
        data: {
          message: err.message,
        },
      };
    }
    return Promise.reject(errorObj);
  },
};

export const simpleRequest = (options) => {
  const simRequest = new AxiosAPI(
    {
      baseURL: configFile.API_URL,
      headers: baseHeaders,
    },
    {
      responseInterceptors,
    },
  );
  return simRequest.make(options);
};

export const authRequest = (options) => {
  const authenticatedRequest = new AxiosAPI(
    {
      baseURL: configFile.API_URL,
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    },
    {
      responseInterceptors,
    },
  );
  return authenticatedRequest.make(options);
};
