import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/github';
import 'brace/ext/searchbox';

const SettingRow = ({setting, editSettingForm}) => (
  <TableRow>
    <TableCell component="th" scope="row">
      {setting.name}
    </TableCell>
    <TableCell style={{maxWidth: 600}}>{setting.value}</TableCell>
    <TableCell>{setting.category}</TableCell>
    <TableCell>{setting.datatype}</TableCell>
    <TableCell>
      <Edit style={{cursor: 'pointer'}} onClick={editSettingForm} />
    </TableCell>
  </TableRow>
);

export const SettingsTable = ({filteredArray, editSettingForm}) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Setting name</TableCell>
        <TableCell>Value</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Datatype</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredArray.map((item) => (
        <SettingRow
          key={item.id}
          setting={item}
          editSettingForm={() => editSettingForm(item)}
        />
      ))}
    </TableBody>
  </Table>
);

export const CategoryForm = ({category, setCategory, categoryList}) => (
  <div>
    <FormControl style={{minWidth: 120}}>
      <InputLabel>Category</InputLabel>
      <Select value={category} onChange={(e) => setCategory(e.target.value)}>
        <MenuItem value="">None</MenuItem>
        {categoryList.map((categoryName) => (
          <MenuItem key={categoryName} value={categoryName}>
            {categoryName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export const SettingEditorForm = ({
  isSettingEditingFormOpen,
  closeEditingForm,
  updatingError,
  currentSetting,
  editSettingValue,
  submitForm,
}) => (
  <Dialog
    fullWidth={true}
    maxWidth="md"
    open={isSettingEditingFormOpen}
    onClose={() => closeEditingForm()}
  >
    <DialogContent>
      {updatingError && <p>{updatingError}</p>}

      <form onSubmit={(e) => submitForm(e)}>
        {['int', 'short-text', 'string'].includes(currentSetting.datatype) ? (
          <TextField
            label={currentSetting.name}
            value={currentSetting.value}
            onChange={editSettingValue}
            margin="normal"
            variant="outlined"
            type={currentSetting.datatype === 'int' ? 'number' : 'text'}
          />
        ) : null}
        {['long-text', 'html', 'json'].includes(currentSetting.datatype) ? (
          <AceEditor
            mode="html"
            theme="github"
            editorProps={{$blockScrolling: true}}
            width={'100%'}
            height={window.innerHeight - 150 + 'px'}
            wrapEnabled={true}
            value={currentSetting.value}
            onChange={editSettingValue}
          />
        ) : null}
        <div>
          <Button variant="contained" color="primary" type="submit">
            Save Setting
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => closeEditingForm()}
          >
            Close
          </Button>
        </div>
      </form>
    </DialogContent>
  </Dialog>
);
