import React, {useEffect, useState} from 'react';
import {
  SettingsTable,
  CategoryForm,
  SettingEditorForm,
} from './global-settings.utils';
import {CircularProgress, Container} from '@material-ui/core';
import {globalSettingsServices} from '../../../../services';

const GlobalSettings = () => {
  const [fetchingError, updateFetchingError] = useState(null);
  const [updatingError, updateUpdatingError] = useState(null);
  const [fetchingData, updateFetchingData] = useState(false);
  const [isSettingEditingFormOpen, updateIsSettingEditingFormOpen] = useState(
    false,
  );
  const [settingsArray, updateSettingsArray] = useState([]);
  const [currentSetting, updateCurrentSetting] = useState({});
  const [category, setCategory] = React.useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [filteredArray, updateFilteredArray] = useState([]);
  useEffect(() => {
    async function fetchVod() {
      updateFetchingData(true);
      updateFetchingError(null);
      try {
        const res = await globalSettingsServices.fetchGlobalSettings();
        updateSettingsArray(
          Object.keys(res.data).map((key, index) => res.data[key]),
        );
        setCategoryList([
          ...new Set(
            Object.keys(res.data).map((item) => res.data[item].category),
          ),
        ]);
      } catch (e) {
        updateFetchingError(e.message);
      }
      updateFetchingData(false);
    }
    fetchVod();
  }, []);

  useEffect(() => {
    const newFilteredArray = category
      ? settingsArray.filter((setting) => setting.category === category)
      : [...settingsArray];
    updateFilteredArray(newFilteredArray);
  }, [category, settingsArray]);
  const editSettingForm = (setting) => {
    updateCurrentSetting(setting);
    updateIsSettingEditingFormOpen(true);
  };

  const editSettingValue = (e) => {
    let value = e;
    if (!['long-text', 'html', 'json'].includes(currentSetting.datatype)) {
      value = e.target.value;
    }
    updateCurrentSetting({
      ...currentSetting,
      value,
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    if (currentSetting.datatype === 'json') {
      try {
        JSON.parse(currentSetting.value);
      } catch (err) {
        updateUpdatingError('Invalid JSON');
        return;
      }
    }

    updateUpdatingError(null);

    try {
      await globalSettingsServices.saveGlobalSetting(currentSetting);
      const index = settingsArray.findIndex(
        (item) => item.id === currentSetting.id,
      );
      updateIsSettingEditingFormOpen(false);
      updateCurrentSetting({});

      const newArr = [
        ...settingsArray.slice(0, index),
        currentSetting,
        ...settingsArray.slice(index + 1, settingsArray.length),
      ];
      updateSettingsArray(newArr);
    } catch (e) {
      updateUpdatingError(e.message);
    }
  };
  const closeEditingForm = () => {
    updateIsSettingEditingFormOpen(false);
    updateCurrentSetting({});
  };
  if (fetchingData) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={100} />
      </div>
    );
  }
  return (
    <Container>
      {fetchingError && <p>{fetchingError}</p>}
      <SettingEditorForm
        isSettingEditingFormOpen={isSettingEditingFormOpen}
        closeEditingForm={closeEditingForm}
        updatingError={updatingError}
        currentSetting={currentSetting}
        editSettingValue={editSettingValue}
        submitForm={submitForm}
      />
      <CategoryForm
        category={category}
        categoryList={categoryList}
        setCategory={setCategory}
      />
      <SettingsTable
        filteredArray={filteredArray}
        editSettingForm={editSettingForm}
      />
    </Container>
  );
};

export default GlobalSettings;
