import React from 'react';
import {
  Link as RouterLink,
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';

import {EmailChange} from './email-change';
import {StorageView} from './storage-view';
import {Grid} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import {EmailOutlined, StorageOutlined} from '@material-ui/icons';

export const Scripts = () => {
  const {path, url} = useRouteMatch();
  return (
    <div>
      <h1>Scripts</h1>
      <Grid container spacing={16}>
        <Grid item md={3}>
          <Paper elevation={0}>
            <List aria-label="main mailbox folders">
              <ListItemLink
                to={url + '/email-change'}
                primary="Email change"
                icon={<EmailOutlined />}
              />
              <ListItemLink
                to={url + '/storage-view'}
                primary="Storage View"
                icon={<StorageOutlined />}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item md={9}>
          <Switch>
            <Route path={path + '/email-change'} component={EmailChange} />
            <Route path={path + '/storage-view'} component={StorageView} />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

// https://material-ui.com/guides/composition/#list
const ListItemLink = ({icon, primary, to}) => {
  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
