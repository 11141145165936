import {authRequest} from '../utils/api';

const fetchGlobalSettings = () => {
  return authRequest({
    url: '/cms/global-setting',
    method: 'GET',
  });
};

const saveGlobalSetting = (setting) => {
  return authRequest({
    url: '/cms/global-setting',
    method: 'POST',
    data: {
      name: setting.name,
      value: setting.value,
    },
  });
};

export const globalSettingsServices = {
  fetchGlobalSettings,
  saveGlobalSetting,
};
