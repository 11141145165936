import React, {useEffect} from 'react';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './navigator';
import {Route, Switch, useHistory} from 'react-router-dom';
import BlogSection from './blog-section';
import TinyMCEEditorForm from './blog-section/tiny-mce-editor';
import GrapesEditorForm from './blog-section/grapes-editor';
import AceEditorForm from './blog-section/ace-editor';
import UserList from './user-list';
import Projects from './user-list/projects';
import Transactions from './transactions';
import GlobalSettings from './global-settings';
import {Scripts} from './scripts';
import Plans from './plans';

let theme = createTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        'boxShadow': 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(2),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        'color': 'inherit',
        'marginRight': 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 200;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    padding: '10px',
    background: '#eaeff1',
  },
};

const Dashboard = ({classes}) => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem('accessToken') === null) {
      history.push('/login');
    }
  }, []);
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden xsDown implementation="css">
            <Navigator />
          </Hidden>
        </nav>
        <div className={classes.appContent}>
          <main className={classes.mainContent}>
            <Switch>
              <Route path="/transactions" component={Transactions} />
              <Route path="/users/:id" component={Projects} />
              <Route path="/users" component={UserList} />
              <Route path="/scripts" component={Scripts} />
              <Route path="/plans" component={Plans} />
              <Route path="/globalsettings" component={GlobalSettings} />
              <Route
                path="/pageform/grapes/:slug"
                component={GrapesEditorForm}
              />
              <Route path="/pageform/ace/:slug" component={AceEditorForm} />
              <Route
                path="/pageform/tiny/:slug"
                component={TinyMCEEditorForm}
              />
              <Route path="/pageform/tiny" component={TinyMCEEditorForm} />
              <Route exact path="/" component={BlogSection} />
            </Switch>
          </main>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(Dashboard);
