import {useEffect, useReducer} from 'react';
import {transactionsServices} from '../../../../services';
import { ITransaction } from './types';

interface IState {
  loading: boolean;
  error: string | null;
  transactions: ITransaction[],
  page: number;
  rowsPerPage: number;
  count: number;
}

const transactionReducer = (state: IState, action: any): IState => {
  switch (action.type) {
    case 'FETCH_TRANSACTIONS_REQUEST': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'FETCH_TRANSACTIONS_SUCCESS': {
      return {
        ...state,
        transactions: action.transactions,
        loading: false,
        count: action.count,
      };
    }
    case 'FETCH_TRANSACTIONS_ERROR': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'UPDATE_PAGE_NUMBER': {
      return {
        ...state,
        page: action.page,
      };
    }
    case 'UPDATE_ROWS_PER_PAGE': {
      return {
        ...state,
        page: 0,
        rowsPerPage: action.rowsPerPage,
      };
    }
    default:
      return state;
  }
};

export const useTransactionState = () => {
  const initialState = {
    loading: false,
    error: null,
    transactions: [],
    page: 0,
    rowsPerPage: 50,
    count: 0,
  };
  const [state, dispatch] = useReducer(transactionReducer, initialState);
  const {page, rowsPerPage} = state;
  useEffect(() => {
    async function fetchTransactions() {
      dispatch({
        type: 'FETCH_TRANSACTIONS_REQUEST',
      });
      try {
        const res: any = await transactionsServices.fetchTransactionsAPI({
          page,
          limit: rowsPerPage,
        });
        const data : {transactions: ITransaction[]; count: number;} = res.data;
        const {transactions, count} = data;
        dispatch({
          type: 'FETCH_TRANSACTIONS_SUCCESS',
          transactions,
          count,
        });
      } catch (e: any) {
        const {message} = e.data;
        dispatch({
          type: 'FETCH_TRANSACTIONS_ERROR',
          message,
        });
      }
    }
    fetchTransactions();
  }, [page, rowsPerPage]);

  const handleChangePage = (e: unknown, newPageNumber: number) => {
    dispatch({
      type: 'UPDATE_PAGE_NUMBER',
      page: newPageNumber,
    });
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'UPDATE_ROWS_PER_PAGE',
      rowsPerPage: e.target.value,
    });
  };

  return {state, handleChangePage, handleChangeRowsPerPage};
};
