import React, {useState, useEffect} from 'react';

import {userListServices} from '../../../../services';
import {Link} from 'react-router-dom';
import {
  TextField,
  CircularProgress,
  Button,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const UserList = () => {
  const [errorMessage, updateErrorMessage] = useState(null);
  const [loading, updateLoading] = useState(false);
  const [userList, updateUserList] = useState([]);
  const [userSearchText, updateUserSearchText] = useState('');
  const [searchingUsers, updateSearchingUser] = useState(false);
  useEffect(() => {
    async function fetchUserList() {
      updateLoading(true);
      try {
        const res = await userListServices.fetchUserList();
        updateUserList(res.data);
      } catch (e) {
        updateErrorMessage(e.data.message);
      }
      updateLoading(false);
    }
    fetchUserList();
  }, []);
  const handleChange = (e) => {
    updateUserSearchText(e.target.value);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    updateSearchingUser(true);
    try {
      const res = await userListServices.fetchUserList(userSearchText);
      updateUserList(res.data);
    } catch (e) {
      updateErrorMessage(e.data.message);
    }
    updateSearchingUser(false);
  };
  if (loading) {
    return (
      <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div>
        {errorMessage && <p>{errorMessage}</p>}
        <form onSubmit={submitForm}>
          <TextField
            style={{width: 400}}
            name="userSearchText"
            id="standard-name"
            label="Search by name, email, hotjarid or analyticsid"
            value={userSearchText}
            onChange={handleChange}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            type={'submit'}
            disabled={searchingUsers}
          >
            SEARCH
          </Button>
        </form>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Country</TableCell>
              <TableCell align="right">registrationTime</TableCell>
              {/* <TableCell align="right">hotjar Id</TableCell>*/}
              <TableCell align="right">Last Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user, key) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  <Link to={`/users/${user.id}`}>{user.name}</Link>
                </TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">{user.country}</TableCell>
                <TableCell align="right">
                  {new Date(user.registrationTime * 1000).toLocaleDateString()}
                </TableCell>
                {/* <TableCell align="right">{user.hotjar}</TableCell>*/}
                <TableCell align="right">
                  {new Date(user.lastLogin * 1000).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserList;
