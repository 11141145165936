import Axios from 'axios';

/**
 * AxiosAPI class
 */
class AxiosAPI {
  /**
   * constructor function
   */
  constructor({baseURL, headers}, {responseInterceptors}) {
    const baseOptions = {
      baseURL,
      headers,
    };
    this.request = Axios.create(baseOptions);
    this.request.interceptors.response.use(
      responseInterceptors.success,
      responseInterceptors.failure,
    );
  }
  /**
   * Axios make function
   * @param {object} options
   * @return {object}
   */
  make(options) {
    options = {
      ...options,
      method: options.method.toLowerCase(),
    };
    return this.request(options);
  }

  /**
   * Parallel calls
   * @return {object}
   */
  parallelCalls() {
    return Axios.all;
  }
}

export default AxiosAPI;
