import {globalSettingsConstants} from '../constants';

const initialState = {
  errorMessage: null,
  loading: false,
  allSettings: {},
  isSettingEditingFormOpen: false,
  updatingCurrentSetting: false,
};
const globalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalSettingsConstants.FETCH_GLOBAL_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case globalSettingsConstants.FETCH_GLOBAL_SETTINGS_SUCCESS:
      return {
        ...state,
        allSettings: action.allSettings,
        loading: false,
      };
    case globalSettingsConstants.FETCH_GLOBAL_SETTINGs_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };
    case globalSettingsConstants.OPEN_SETTING_EDITING_FORM:
      return {
        ...state,
        isSettingEditingFormOpen: true,
        currentSetting: action.currentSetting,
      };
    case globalSettingsConstants.EDIT_CURRENT_SETTING: {
      const {currentSetting} = state;
      return {
        ...state,
        currentSetting: {
          ...currentSetting,
          value: action.editingSettingVaue,
        },
      };
    }
    case globalSettingsConstants.UPDATE_GLOBAL_SETTING_REQUEST: {
      return {
        ...state,
        updatingCurrentSetting: true,
      };
    }
    case globalSettingsConstants.UPDATE_GLOBAL_SETTING_SUCCESS: {
      return {
        ...state,
        updatingCurrentSetting: false,
        isSettingEditingFormOpen: false,
        currentSetting: null,
      };
    }
    case globalSettingsConstants.UPDATE_GLOBAL_SETTING_FAILUE: {
      return {
        ...state,
        updatingCurrentSetting: false,
      };
    }
    case globalSettingsConstants.CLOSE_CURRENT_SETTING: {
      return {
        ...state,
        isSettingEditingFormOpen: false,
      };
    }
    default:
      return state;
  }
};

export default globalSettingsReducer;
