import {authRequest} from '../utils/api';

const fetchUserPlansAPI = () => {
  return authRequest({
    url: '/cms/plans',
    method: 'GET',
  });
};

const savePlanAPI = (data) => {
  return authRequest({
    url: '/cms/plans',
    method: 'POST',
    data,
  });
};

export const PlansServices = {
  fetchUserPlansAPI,
  savePlanAPI,
};
