import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import OpenInNew from '@material-ui/icons/OpenInNew';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {useApi, headerWithAuth} from '../../../../utils/api/hook';
import {withStyles} from '@material-ui/core/styles';

const fetchBlogs = () =>
  fetch('/api/cms/pages?limit=100', {
    method: 'GET',
    headers: {
      ...headerWithAuth(),
      'Content-Type': 'application/json',
    },
  });

const styles = (theme) => ({
  errorAlert: {
    background: '#ffee88',
    padding: theme.spacing(2, 1),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2, 0),
    fontWeight: 'bold',
  }
});

const BlogSection = ({classes}) => {
  const {data, loading, error, call} = useApi(fetchBlogs);
  useEffect(() => {
    call();
  }, []);
  const {rows: pages = []} = data || {};
  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  return (
    <div>
      <p className={classes.errorAlert}>Page editing will not reflect on website currently as it is getting updated.</p>
      <Link to="/pageform/tiny">
        <Button variant="contained" color="primary">
          Create Page
        </Button>
      </Link>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="right">Author</TableCell>
            <TableCell align="right">Slug</TableCell>
            <TableCell align="right">Grapes JS</TableCell>
            <TableCell align="right">Tiny MCE</TableCell>
            <TableCell align="right">ACE</TableCell>
            <TableCell align="right">createdAt</TableCell>
            <TableCell align="right">updatedAt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pages.map((page) => (
            <TableRow key={page.id}>
              <TableCell component="th" scope="row">
                {page.title}
              </TableCell>
              <TableCell align="right">{page.author}</TableCell>
              <TableCell align="right">
                <a
                  href={`https://www.vdocipher.com/${page.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {page.slug}
                  <OpenInNew />
                </a>
              </TableCell>
              <TableCell align="right">
                <Link to={`/pageform/grapes/${page.uuid}`}>GrapeJS</Link>
              </TableCell>
              <TableCell align="right">
                <Link to={`/pageform/tiny/${page.uuid}`}>TinyMCE</Link>
              </TableCell>
              <TableCell align="right">
                <Link to={`/pageform/ace/${page.uuid}`}>ACE</Link>
              </TableCell>
              <TableCell align="right">
                {new Date(page.createdAt).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {new Date(page.updatedAt).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(BlogSection);
