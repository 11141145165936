import {userConstants} from '../constants';

const initialState = {
  errorMessage: null,
  loggedIn: false,
};
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: action.error.message,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
