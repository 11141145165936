import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TablePagination, TableFooter} from '@material-ui/core';
import {ITransaction, handleChangePageType, handleChangeRowsPerPageType} from './types';

interface ITransactionTableOwnProps {
  transactions: ITransaction[],
  handleChangePage: handleChangePageType,
  handleChangeRowsPerPage: handleChangeRowsPerPageType,
  count: number,
  rowsPerPage: number,
  page: number,
}

const TransactionTable: React.FC<ITransactionTableOwnProps> = ({
  transactions,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
  rowsPerPage,
  page,
}): JSX.Element => {
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User Id</TableCell>
            <TableCell>Payment Token</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Payment Method</TableCell>
            {/*
            <TableCell>Payment Status</TableCell>
            */}
            <TableCell>Invoice Id</TableCell>
            <TableCell>Plan Id</TableCell>
            <TableCell>Country Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((l) => (
            <TableRow key={l.id}>
              <TableCell>{l.user}</TableCell>
              <TableCell>{l.token}</TableCell>
              <TableCell title={new Date(l.time * 1000).toISOString()}>
                {new Date(l.time * 1000).toLocaleDateString()}
              </TableCell>
              <TableCell style={{backgroundColor: l.oddCard ? 'red' : 'none'}}>
                {l.amount_inr ? '₹' + l.amount_inr : '$' + l.amount}
              </TableCell>
              <TableCell title={l.method}>
                {l.method.toLowerCase().substr(0, 4)}
              </TableCell>
              <TableCell>
                {l.downloadInvoiceToken ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${l.downloadInvoiceToken}`}
                  >
                    {l.invoice_id}
                  </a>
                ) : (
                  l.invoice_id
                )}
              </TableCell>
              <TableCell>{l.plan}</TableCell>
              <TableCell
                style={{
                  backgroundColor:
                    l.billedCountryCode === 'IN' ? 'lightgreen' : 'none',
                }}
              >
                {l.billedCountryCode}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      </React.Fragment>
  );
};

export default TransactionTable;
