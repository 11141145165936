import React from 'react';
import {SnackbarProvider} from 'notistack';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {HomePage, LoginPage} from './containers';

const App = () => {
  return (
    <SnackbarProvider>
      <div className="jumbotron">
        <div className="container">
          <div className="col-sm-8 col-sm-offset-2">
            <Router>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/" component={HomePage} />
              </Switch>
            </Router>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
};

export default App;
