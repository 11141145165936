import React from 'react';
import {Paper} from '@material-ui/core';
import TransactionTable from './transaction-table';
import {useTransactionState} from './transaction-state-hook';
import {ITransaction, handleChangePageType, handleChangeRowsPerPageType} from './types';

interface IState {
  page: number;
  rowsPerPage: number;
  count: number;
  transactions: ITransaction[];
  loading: boolean;
}

const Transactions:React.FC = (): JSX.Element => {
  const {
    state,
    handleChangePage,
    handleChangeRowsPerPage,
  } : {state : IState;
    handleChangePage: handleChangePageType;
    handleChangeRowsPerPage: handleChangeRowsPerPageType;} = useTransactionState();
  const {page, rowsPerPage, count, loading} = state;
  let {transactions} = state;
  if (loading) {
    return <div>loading....</div>;
  }
  /** xor operator **/
  transactions = transactions.map(
    (t: ITransaction) => {
      let oddCard: any =
        t.billedCountryCode &&
        t.isInternational || (t.billedCountryCode !== 'IN');
      return {
        ...t,
        oddCard
      }
    });
  return (
    <Paper>
      <TransactionTable
        transactions={transactions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
      />
    </Paper>
  );
};

export default Transactions;
