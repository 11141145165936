import {authRequest} from '../utils/api';

const fetchProjects = (id) => {
  return authRequest({
    url: `/cms/users/${id}`,
    method: 'GET',
  });
};

const fetchProjectSettings = (projectId) => {
  return authRequest({
    url: `/cms/users/${projectId}/settings`,
    method: 'GET',
  });
};

const updateProjectSetting = (data) => {
  return authRequest({
    url: `/cms/users/${data.projectId}/update-user-settings/${data.id}`,
    method: 'PUT',
    data,
  });
};

const fetchAllProjects = (order = '', accountType, sort) => {
  return authRequest({
    url: `/cms/user-accounts?order=${order}&account-type=${accountType}&sort=${sort}`,
    method: 'GET',
  });
};

export const projectsServices = {
  fetchProjects,
  fetchProjectSettings,
  updateProjectSetting,
  fetchAllProjects,
};
