/**
 * round to the given number of significant digits
 * @param {Number} n the input which gets rounded
 * @param {Number} k required number of significant digits
 * @return {Number}
 */
const round = (n, k) => {
  const tenPower = Math.floor(Math.log10(n));
  const roundingFactor = Math.pow(10, k - tenPower - 1);
  return Math.round(n * roundingFactor) / roundingFactor;
};

/**
 * format the number of bytes as a formatted string with the best
 * fit units. Can specify how many significant digits needed
 * @param {Number} bytes
 * @param {Number} significant
 * @return {String}
 */
exports.formatBytes = (bytes, significant = 3) => {
  if (bytes < 1024 * 1024) {
    return `${round(bytes / 1024, significant)} kB`;
  } else if (bytes < 1024 * 1024 * 1024) {
    return `${round(bytes / 1024 / 1024, significant)} MB`;
  } else if (bytes < 1024 * 1024 * 1024 * 1024) {
    return `${round(bytes / 1024 / 1024 / 1024, significant)} GB`;
  } else if (bytes < 1024 * 1024 * 1024 * 1024 * 1024) {
    return `${
      round((bytes * 10) / 1024 / 1024 / 1024 / 1024, significant) / 10
    } TB`;
  } else {
    return `${
      round((bytes * 10) / 1024 / 1024 / 1024 / 1024 / 1024, significant) / 10
    } PB`;
  }
};
