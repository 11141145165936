import {useReducer, useEffect} from 'react';
import {PlansServices} from '../../../../services';
const {fetchUserPlansAPI, savePlanAPI} = PlansServices;

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_FORM_ELEMENT': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'FETCH_LIST_REQUEST': {
      return {
        ...state,
        fetchingList: true,
      };
    }
    case 'FETCH_LIST_SUCCESS': {
      return {
        ...state,
        fetchingList: false,
        list: action.list,
      };
    }
    case 'FETCH_LIST_ERROR': {
      return {
        ...state,
        fetchingList: false,
        error: action.err,
      };
    }
    case 'SAVE_FORM_REQUEST': {
      return {
        ...state,
        savingForm: true,
      };
    }
    case 'SAVE_FORM_SUCCESS': {
      return {
        ...state,
        savingForm: false,
        list: [action.data, ...state.list],
      };
    }
    case 'SAVE_FORM_ERROR': {
      return {
        ...state,
        savingForm: false,
        error: action.err,
      };
    }
    case 'RESET_FORM': {
      return {
        ...state,
        newPlanName: '',
        newPlanStorage: 0,
        newPlanValidity: 365,
        newPlanAmount: 0,
        newPlanBandwidth: 0,
        validityLogic: 'extend-from-recharge-date',
        date: new Date(),
        openCreateForm: false,
      };
    }
    case 'FORM_STATUS': {
      return {
        ...state,
        openCreateForm: true,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  newPlanName: '',
  newPlanStorage: 0,
  newPlanValidity: 365,
  newPlanAmount: 0,
  newPlanBandwidth: 0,
  validityLogic: 'extend-from-recharge-date',
  date: new Date(),
  fetchingList: false,
  list: [],
  error: null,
  openCreateForm: false,
  savingForm: false,
};
export const usePlansStateHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onChange = (e) => {
    dispatch({
      type: 'CHANGE_FORM_ELEMENT',
      field: e.target.name,
      value: e.target.value,
    });
  };
  const fetchList = async () => {
    dispatch({
      type: 'FETCH_LIST_REQUEST',
    });
    try {
      const res = await fetchUserPlansAPI();
      dispatch({
        type: 'FETCH_LIST_SUCCESS',
        list: res.data,
      });
    } catch (err) {
      dispatch({
        type: 'FETCH_LIST_ERROR',
        err: err.data.message,
      });
    }
  };
  useEffect(() => {
    fetchList();
  }, []);
  const saveForm = async (e) => {
    e.preventDefault();
    dispatch({
      type: 'SAVE_FORM_REQUEST',
    });
    try {
      const {
        newPlanName,
        newPlanStorage,
        newPlanValidity,
        newPlanAmount,
        newPlanBandwidth,
        validityLogic,
        date,
      } = state;

      const res = await savePlanAPI({
        planName: newPlanName,
        storage: Number(newPlanStorage) * 1024,
        bandwidth: Number(newPlanBandwidth) * 1024 * 1024,
        validity: Number(newPlanValidity),
        amount: Number(newPlanAmount),
        planParams: {
          validityLogic,
          extendTillDate:
            validityLogic === 'extend-by-date'
              ? Math.floor(new Date(date).getTime() / 1000)
              : null,
        },
      });
      dispatch({
        type: 'SAVE_FORM_SUCCESS',
        data: res.data,
      });
      dispatch({
        type: 'RESET_FORM',
      });
    } catch (err) {
      dispatch({
        type: 'SAVE_FORM_ERROR',
        err: err.data.message,
      });
    }
  };
  const updateOpenCreateForm = (status) => {
    dispatch({
      type: 'FORM_STATUS',
      status,
    });
  };
  const changeDate = (date) => {
    dispatch({
      type: 'CHANGE_FORM_ELEMENT',
      field: 'date',
      value: date,
    });
  };

  return {state, onChange, saveForm, updateOpenCreateForm, changeDate};
};
