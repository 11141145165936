import {simpleRequest} from '../utils/api';

const login = (data) => {
  return simpleRequest({
    url: '/login',
    method: 'POST',
    data: data,
  });
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('accessToken');
};

const saveAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

export const userService = {
  login,
  logout,
  saveAccessToken,
};
