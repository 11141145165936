import React, {Component} from 'react';

import TinyMCE from 'react-tinymce';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {blogsActions} from '../../../../actions';
import {EditorHeader} from './editor-header';

const {fetchPage, updatePage, createPage, deletePage} = blogsActions;

class TinyMCEEditorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      body: '',
      metaTags: [],
      slug: '',
      uuid: '',
      permission: 0,
    };
  }
  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }
  handleEditorChange(e) {
    this.setState({
      body: e.target.getContent(),
    });
  }
  componentDidMount() {
    const {match} = this.props;
    const {params} = match;
    const {slug} = params;
    if (slug) {
      this.props.fetchPage(slug);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.page && this.props.page !== prevProps.page) {
      const {id, title, body, metaTags, slug, uuid} = this.props.page;
      this.setState({id, title, body, metaTags, slug, uuid});
    }
  }

  onCreate(e) {
    e.preventDefault();
    const {title, body, slug, metaTags, permission = 0} = this.state;
    this.props.createPage({title, body, slug, metaTags, permission});
  }
  onUpdate() {
    const {uuid, title, body, slug, metaTags, permission = 0} = this.state;
    this.props.updatePage({uuid, title, body, slug, metaTags, permission});
  }
  onDelete() {
    const {uuid} = this.state;
    this.props.deletePage(uuid);
  }

  render() {
    const {body} = this.state;
    const {match} = this.props;
    const {params} = match;
    if (params.slug && !this.state.slug) {
      return <div>loading...</div>;
    }
    return (
      <div>
        <EditorHeader
          onChange={this.onChange.bind(this)}
          parentState={this.state}
          parentProps={this.props}
          onCreate={this.onCreate.bind(this)}
          onUpdate={this.onUpdate.bind(this)}
          onDelete={this.onDelete.bind(this)}
        />
        <div>
          <TinyMCE
            content={body}
            name="body"
            config={{
              theme: 'modern',
              height: window.innerHeight - 150 + 'px',
              plugins: [
                'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                'searchreplace wordcount visualblocks visualchars code codesample',
                'fullscreen insertdatetime media nonbreaking',
                'save table contextmenu directionality emoticons template paste textcolor',
              ],
              valid_children: '+body[style],-body[div],p[strong|a|#text]',
              valid_elements: '+*[*]',
              verify_html: false,
              content_css: [
                'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/css/materialize.min.css',
                'https://www.vdocipher.com/app.css',
                'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css',
                'https://file.myfontastic.com/cytUpTLomtjmQMLQ5iUXkG/icons.css',
              ],
              toolbar:
                `insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ` +
                `bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons`,
            }}
            onChange={(e) => this.handleEditorChange(e)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {blogs} = state;
  return blogs;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {fetchPage, updatePage, createPage, deletePage},
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TinyMCEEditorForm);
