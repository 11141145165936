export const blogsConstants = {
  FETCH_PAGES_REQUEST: 'FETCH_PAGES_REQUEST',
  FETCH_PAGES_SUCCESS: 'FETCH_PAGES_SUCCESS',
  FETCH_PAGES_FAILURE: 'FETCH_PAGES_FAILURE',

  FETCH_PAGE_REQUEST: 'FETCH_PAGE_REQUEST',
  FETCH_PAGE_SUCCESS: 'FETCH_PAGE_SUCCESS',
  FETCH_PAGE_FAILURE: 'FETCH_PAGE_FAILURE',

  CREATE_PAGE_REQUEST: 'CREATE_PAGE_REQUEST',
  CREATE_PAGE_SUCCESS: 'CREATE_PAGE_SUCCESS',
  CREATE_PAGE_FAILURE: 'CREATE_PAGE_FAILURE',

  UPDATE_PAGE_REQUEST: 'UPDATE_PAGE_REQUEST',
  UPDATE_PAGE_SUCCESS: 'UPDATE_PAGE_SUCCESS',
  UPDATE_PAGE_FAILURE: 'UPDATE_PAGE_FAILURE',

  DELETE_PAGE_REQUEST: 'DELETE_PAGE_REQUEST',
  DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
  DELETE_PAGE_FAILURE: 'DELETE_PAGE_FAILURE',
};
