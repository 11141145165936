import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {TextField, Button} from '@material-ui/core';

import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/github';
import 'brace/ext/searchbox';

const ProjectSetting = (props) => {
  const {
    allSettings,
    handleChange,
    settingName,
    settingValue,
    submitSetting,
    userSettings,
    deleteSetting,
    updatingSetting,
    editSettingForm,
    showEditSettingForm,
  } = props;
  return (
    <div>
      {editSettingForm ? (
        <form onSubmit={submitSetting}>
          <div>
            <FormControl style={{width: 200}}>
              <Select
                value={settingName}
                name="settingName"
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="none">
                  <em>None</em>
                </MenuItem>
                {allSettings.map((setting, index) => (
                  <MenuItem key={setting.id} value={setting.id}>
                    {setting.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>User Setting</FormHelperText>
            </FormControl>
          </div>
          <div>
            {settingName === 'notices' ? (
              <AceEditor
                mode="html"
                theme="github"
                editorProps={{$blockScrolling: true}}
                width={500}
                height={window.innerHeight - 300 + 'px'}
                wrapEnabled={true}
                value={settingValue}
                onChange={(e) => handleChange(e)}
              />
            ) : (
              <TextField
                label="Value"
                name="settingValue"
                value={settingValue}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            )}
          </div>
          <div>
            <Button
              disabled={updatingSetting}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => showEditSettingForm(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => showEditSettingForm(true)}
        >
          Add/Update Setting
        </Button>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">Setting Name</TableCell>
            <TableCell align="right">Setting Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allSettings.length > 0 &&
            Object.keys(userSettings).map((settingName, index) => (
              <TableRow key={index}>
                <TableCell align="right">{settingName} </TableCell>
                <TableCell align="right">{userSettings[settingName]}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteSetting(settingName)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProjectSetting;
