export const globalSettingsConstants = {
  FETCH_GLOBAL_SETTINGS_REQUEST: 'FETCH_GLOBAL_SETTINGS_REQUEST',
  FETCH_GLOBAL_SETTINGS_SUCCESS: 'FETCH_GLOBAL_SETTINGS_SUCCESS',
  FETCH_GLOBAL_SETTINGS_FAILURE: 'FETCH_GLOBAL_SETTINGS_FAILURE',
  OPEN_SETTING_EDITING_FORM: 'OPEN_SETTING_EDITING_FORM',
  EDIT_CURRENT_SETTING: 'EDIT_CURRENT_SETTING',
  UPDATE_GLOBAL_SETTING_REQUEST: 'UPDATE_GLOBAL_SETTING_REQUEST',
  UPDATE_GLOBAL_SETTING_SUCCESS: 'UPDATE_GLOBAL_SETTING_SUCCESS',
  UPDATE_GLOBAL_SETTING_FAILUE: 'UPDATE_GLOBAL_SETTING_FAILURE',
  CLOSE_CURRENT_SETTING: 'CLOSE_CURRENT_SETTING',
};
