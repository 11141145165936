import React, {useState, useReducer, useEffect, Fragment} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import projectsReducer from '../../../../../reducers/projects.reducer';
import {projectsConstants} from '../../../../../constants';
import {projectsServices} from '../../../../../services';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProjectSettings from './project-settings';
import MainProject from './main-project';
import RechargeUser from './recharge-user';
import {Button, CircularProgress} from '@material-ui/core';
const UserList = (props) => {
  const initialState = {
    errorMessage: null,
    loading: false,
    projects: [],
    currentProject: null,
    allSettings: [],
    settingName: 'none',
    settingValue: '',
    userSettings: [],
    tabValue: 0,
    videos: [],
    transactions: [],
    fetchingSettings: false,
    updatingSetting: false,
    deletingSetting: false,
  };
  const [state, dispatch] = useReducer(projectsReducer, initialState);
  const [editSettingForm, showEditSettingForm] = useState(false);
  useEffect(() => {
    async function fetchUserList() {
      dispatch({
        type: projectsConstants.FETCH_PROJECTS_REQUEST,
      });
      try {
        const res = await projectsServices.fetchProjects(props.match.params.id);
        dispatch({
          type: projectsConstants.FETCH_PROJECTS_SUCCESS,
          projects: res.data,
        });
      } catch (e) {
        dispatch({
          type: projectsConstants.FETCH_PROJECTS_FAILURE,
          message: e.data,
        });
      }
    }
    fetchUserList();
  }, []);
  const showProjectSettings = async (project) => {
    dispatch({
      type: projectsConstants.FETCH_PROJECTS_SETTINGS_REQUEST,
    });
    try {
      const res = await projectsServices.fetchProjectSettings(project.id);
      const {transactions, videos, userSettings, allSettings} = res.data;
      dispatch({
        type: projectsConstants.FETCH_PROJECT_SETTINGS_SUCCESS,
        transactions,
        videos,
        userSettings,
        allSettings,
        currentProject: project,
      });
    } catch (e) {
      dispatch({
        type: projectsConstants.FETCH_PROJECTS_SETTINGS_FAILURE,
        message: e.data,
      });
    }
  };
  const handleChange = (e) => {
    dispatch({
      type: projectsConstants.SETTING_INPUT_VALUE_CHANGE,
      name: e.target ? e.target.name : 'settingValue',
      value: e.target ? e.target.value : e,
    });
  };
  const changeTab = (e, newValue) => {
    dispatch({
      type: projectsConstants.CHANGE_TAB_VALUE,
      tabValue: newValue,
    });
  };
  const submitSetting = async (e) => {
    e.preventDefault();

    const {currentProject, settingName, settingValue} = state;
    dispatch({
      type: projectsConstants.UPDATE_PROJECT_SETTING_REQUEST,
    });
    try {
      await projectsServices.updateProjectSetting({
        projectId: currentProject.id,
        id: settingName,
        value: settingValue,
      });
      dispatch({
        type: projectsConstants.UPDATE_PROJECT_SETTING_SUCCESS,
        name: allSettings.find((setting) => setting.id === settingName).name,
        value: settingValue,
      });
      showEditSettingForm(false);
    } catch (e) {
      dispatch({
        type: projectsConstants.UPDATE_PROJECT_SETTING_FAILURE,
        message: e.data,
      });
    }
  };
  const deleteSetting = async (settingName, index) => {
    const setting = allSettings.find((setting) => setting.name === settingName);
    try {
      dispatch({
        type: projectsConstants.DELETE_PROJECT_SETTING_REQUEST,
      });
      await projectsServices.updateProjectSetting({
        projectId: currentProject.id,
        id: setting.id,
      });
      dispatch({
        type: projectsConstants.DELETE_PROJECT_SETTING_SUCCESS,
        name: settingName,
      });
    } catch (err) {
      dispatch({
        type: projectsConstants.DELETE_PROJECT_SETTING_FAILURE,
      });
    }
  };
  const closeSettings = () => {
    dispatch({
      type: projectsConstants.SHOW_CURRENT_PROJECT_SETTINGS,
    });
  };

  const {
    projects,
    currentProject,
    allSettings,
    settingName,
    settingValue,
    userSettings,
    tabValue,
    fetchingSettings,
    updatingSetting,
  } = state;
  return (
    <div>
      <h3>Account List of the User</h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Project ID</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Country</TableCell>
              <TableCell align="right">Created At</TableCell>
              <TableCell align="right">Plan</TableCell>
              {/* <TableCell align="right">Last Login</TableCell>*/}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {projects &&
              projects.map((project, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {project.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {project.id}
                  </TableCell>
                  <TableCell align="right">{project.email}</TableCell>
                  <TableCell align="right">{project.country}</TableCell>
                  <TableCell align="right">
                    {new Date(project.registrationTime * 1000).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{project.plan}</TableCell>
                  {/* <TableCell align="right">
                  {new Date(project.lastLogin * 1000).toLocaleString()}
            </TableCell>*/}
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showProjectSettings(project)}
                    >
                      More Info
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {fetchingSettings ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          {currentProject && (
            <div style={{width: 800, margin: '50px auto'}}>
              <Paper square>
                <Tabs
                  value={tabValue}
                  onChange={changeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Information" />
                  <Tab label="Settings" />
                  <Tab label="Recharge" />
                </Tabs>
              </Paper>
              {tabValue === 0 && <MainProject project={currentProject} />}
              {tabValue === 1 && (
                <ProjectSettings
                  closeSettings={closeSettings}
                  submitSetting={submitSetting}
                  allSettings={allSettings}
                  handleChange={handleChange}
                  settingName={settingName}
                  settingValue={settingValue}
                  userSettings={userSettings}
                  deleteSetting={deleteSetting}
                  updatingSetting={updatingSetting}
                  editSettingForm={editSettingForm}
                  showEditSettingForm={showEditSettingForm}
                />
              )}
              {tabValue === 2 && (
                <RechargeUser
                  project={currentProject}
                  userSettings={userSettings}
                  allSettings={allSettings}
                />
              )}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default UserList;
