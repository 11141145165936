import {useHistory} from 'react-router-dom';
import {useCallback, useState} from 'react';

class Auth {
  constructor() {
    this.token = localStorage.getItem('accessToken');
  }
  login(token) {
    localStorage.setItem('accessToken', token);
    this.token = token;
  }
  logout() {
    localStorage.removeItem('accessToken');
    this.token = null;
  }
  getToken() {
    if (!this.token) {
      this.token = localStorage.getItem('accessToken');
    }
    return this.token;
  }
}

const auth = new Auth();

export const headerWithAuth = () =>
  auth.getToken()
    ? {
        authorization: 'Bearer ' + auth.getToken(),
      }
    : {};

/**
 * APIError class for throwing rich error when an xhr fails
 */
const APIError = class extends Error {
  /**
   * @param {{}} response
   * @param {{}|string} result
   * @param {string} message
   */
  constructor(response, result, message) {
    super();
    this.response = response;
    this.message = message;
    this.result = result;
  }
};

const parseFetchResponse = async (response) => {
  let result;
  let errorMessage = 'API Error';
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.match('application/json')) {
    result = await response.json();
    if (result.message) errorMessage = result.message;
  } else {
    result = await response.text();
    errorMessage +=
      result.substring(0, 500) + (result.length > 500 ? '...' : '');
  }
  if (response.status !== 200) {
    throw new APIError(response, result, `${response.status} ${errorMessage}`);
  }
  return result;
};

export const useApi = (fn) => {
  const history = useHistory();
  const [{data, loading, error, status, count}, setState] = useState({
    data: null,
    loading: false,
    error: null,
    status: -2,
    count: 0,
  });
  const loadApi = useCallback(
    (...args) => {
      setState((state) => ({
        ...state,
        loading: true,
        status: -1,
        c: state.c + 1,
        error: null,
      }));
      return fn(...args)
        .then((response) => parseFetchResponse(response))
        .then((data) =>
          setState((state) => ({
            ...state,
            loading: false,
            data,
            error: null,
            status: 200,
          })),
        )
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            auth.logout();
            history.push('/login');
            return;
          }
          setState((state) => ({
            ...state,
            loading: false,
            error,
            status: error.response ? error.response.status : 0,
          }));
        });
    },
    [fn, history],
  );
  return {
    data,
    loading,
    error,
    status,
    count,
    call: loadApi,
  };
};
