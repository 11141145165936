import React from 'react';
import {Grid, TextField} from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

const makePreviewUrl = (slug) => {
  let url = `https://www.vdocipher.com/${slug}`
  if (!url.endsWith('/')) url += '/';
  return url + '?previewToken=helloworld';
}

export const EditorHeader = (props) => {
  const {
    onChange,
    onDelete,
    onUpdate,
    onCreate,
    parentState: {uuid, title, metaTags, slug},
    parentProps: {loading},
  } = props;
  let submitButton;
  let deleteButton;
  let updateButton;
  if (uuid) {
    deleteButton = (
      <Button variant="contained" color="secondary" onClick={onDelete}>
        Delete
      </Button>
    );
    updateButton = (
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={onUpdate}
      >
        {loading ? 'Updating' : 'Update'}
      </Button>
    );
  } else {
    submitButton = (
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={onCreate}
      >
        {loading ? 'Creating' : 'Create'}
      </Button>
    );
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={1}>
          <Link to="/">
            <Button
              variant="contained"
              color="primary"
              style={{marginBottom: 10}}
            >
              Back
            </Button>
          </Link>
        </Grid>
        <Grid item xs={3}>
          Slug:{' '}
          <TextField
            name="slug"
            required={true}
            type="text"
            value={slug}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={3}>
          Title:{' '}
          <TextField
            name="title"
            required={true}
            value={title}
            type="text"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={2}>
          {submitButton}
          {updateButton}
          {deleteButton}
        </Grid>
        <Grid item xs={1}>
          <a
            href={makePreviewUrl(slug)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <OpenInNew /> Preview
          </a>
        </Grid>
      </Grid>
      <div>
        <TextField
          name="metaTags"
          multiline
          rows="2"
          required={true}
          type="text"
          value={metaTags}
          inputProps={{
            style: {fontFamily: 'monospace'},
          }}
          margin="normal"
          fullWidth
          onChange={onChange}
        />
      </div>
    </div>
  );
};
