import {authRequest} from '../utils/api';

const fetchPages = () => {
  return authRequest({
    url: '/cms/pages?limit=100',
    method: 'GET',
  });
};

const fetchPage = (slug) => {
  return authRequest({
    url: `/cms/pages/${slug}`,
    method: 'GET',
  });
};

const createPage = (data) => {
  return authRequest({
    url: `/cms/pages`,
    method: 'PUT',
    data,
  });
};

const updatePage = (data) => {
  return authRequest({
    url: `/cms/pages/${data.uuid}`,
    method: 'POST',
    data,
  });
};

const deletePage = (id) => {
  return authRequest({
    url: `/cms/pages/${id}`,
    method: 'DELETE',
  });
};

export const blogsServices = {
  fetchPages,
  createPage,
  updatePage,
  deletePage,
  fetchPage,
};
