import {projectsConstants} from '../constants';

const initialState = {
  errorMessage: null,
  loading: false,
  projects: [],
  currentProject: null,
  allSettings: [],
  settingName: 'none',
  settingValue: '',
  userSettings: [],
  tabValue: 0,
  videos: [],
  transactions: [],
  deletingSetting: false,
};
const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectsConstants.FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        loading: false,
      };
    case projectsConstants.FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };
    case projectsConstants.FETCH_PROJECTS_SETTINGS_REQUEST:
      return {
        ...state,
        fetchingSettings: true,
      };
    case projectsConstants.FETCH_PROJECT_SETTINGS_SUCCESS:
      return {
        ...state,
        videos: action.videos,
        transactions: action.transactions,
        userSettings: action.userSettings,
        fetchingSettings: false,
        allSettings: action.allSettings,
        currentProject: action.currentProject,
        tabValue: 0,
      };
    case projectsConstants.FETCH_PROJECTS_SETTINGS_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        fetchingSettings: false,
      };
    case projectsConstants.SHOW_CURRENT_PROJECT_SETTINGS:
      return {
        ...state,
        currentProject: null,
      };
    case projectsConstants.SETTING_INPUT_VALUE_CHANGE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case projectsConstants.UPDATE_PROJECT_SETTING_REQUEST:
      return {
        ...state,
        updatingSetting: true,
      };
    case projectsConstants.UPDATE_PROJECT_SETTING_SUCCESS: {
      const {userSettings} = state;
      return {
        ...state,
        userSettings: {
          ...userSettings,
          [action.name]: action.value,
        },
        updatingSetting: false,
      };
    }
    case projectsConstants.UPDATE_PROJECT_SETTING_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        updatingSetting: false,
      };
    case projectsConstants.DELETE_PROJECT_SETTING_REQUEST:
      return {
        ...state,
        deletingSetting: true,
      };
    case projectsConstants.DELETE_PROJECT_SETTING_SUCCESS: {
      const {userSettings} = state;
      delete userSettings[action.name];
      return {
        ...state,
        userSettings,
        deletingSetting: false,
      };
    }
    case projectsConstants.DELETE_PROJECT_SETTING_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        deletingSetting: false,
      };
    case projectsConstants.CHANGE_TAB_VALUE:
      return {
        ...state,
        tabValue: action.tabValue,
      };
    default:
      return state;
  }
};

export default projectsReducer;
