import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {blogsActions} from '../../../../actions';
import {EditorHeader} from './editor-header';

const {fetchPage, updatePage, createPage, deletePage} = blogsActions;

const beautifyHtml = require('js-beautify').html;
class GrapesEditorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      body: '',
      metaTags: [],
      slug: '',
      uuid: '',
      permission: 0,
    };
  }
  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }
  handleEditorChange(e) {
    this.setState({
      body: e.target.getContent(),
    });
  }
  componentDidMount() {
    const {match} = this.props;
    const {params} = match;
    const {slug} = params;
    if (slug) {
      this.props.fetchPage(slug);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.page && this.props.page !== prevProps.page) {
      const {title, body, metaTags, slug, uuid} = this.props.page;
      this.editor = window.grapesjs.init({
        container: '#gjs',
        components: `${body}`,
        height: window.innerHeight - 150 + 'px',
        protectedCss: '',
        plugins: ['gjs-blocks-basic'],
        pluginsOpts: {
          'gjs-blocks-basic': {
            /* ...options */
          },
        },
        canvas: {
          styles: ['https://www.vdocipher.com/app.css'],
        },
        keepUnusedStyles: 1,
        storageManager: {type: 'none'},
      });
      this.setState({title, metaTags, slug, uuid});
    }
  }

  onCreate(e) {
    e.preventDefault();
    let {title, body, slug, metaTags, permission} = this.state;
    body = beautifyHtml(this.editor.getHtml());
    const css = beautifyHtml(this.editor.getCss());
    this.props.createPage({
      title,
      body: `<style>${css}</style> ${body}`,
      slug,
      metaTags,
      permission,
    });
  }
  onUpdate() {
    let {uuid, title, body, slug, metaTags, permission} = this.state;
    body = beautifyHtml(this.editor.getHtml());
    const css = beautifyHtml(this.editor.getCss());

    this.props.updatePage({
      uuid,
      title,
      body: `<style>${css}</style> ${body}`,
      slug,
      metaTags,
      permission,
    });
  }
  onDelete() {
    const {uuid} = this.state;
    this.props.deletePage(uuid);
  }

  render() {
    return (
      <div>
        <EditorHeader
          onChange={this.onChange.bind(this)}
          parentState={this.state}
          parentProps={this.props}
          onCreate={this.onCreate.bind(this)}
          onUpdate={this.onUpdate.bind(this)}
          onDelete={this.onDelete.bind(this)}
        />
        <div id="gjs"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {blogs} = state;
  return blogs;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {fetchPage, updatePage, createPage, deletePage},
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GrapesEditorForm);
