import {authRequest} from '../utils/api';

const fetchTransactionsAPI = ({page = 0, limit = 10, userId = ''}) => {
  return authRequest({
    url: `/cms/transactions?page=${page}&limit=${limit}&user=${userId}`,
    method: 'GET',
  });
};
const createNewRechargeAPI = (rechargeInfo) => {
  return authRequest({
    url: '/cms/recharge',
    method: 'POST',
    data: rechargeInfo,
  });
};
export const transactionsServices = {
  fetchTransactionsAPI,
  createNewRechargeAPI,
};
