import React from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {userService} from '../../../services';
import {
  CodeSharp,
  MonetizationOn,
  People,
  Settings,
  PostAdd,
  Money,
} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
const {logout} = userService;

const list = [
  {label: 'Pages', url: '/', icon: <PostAdd />},
  {label: 'Settings', url: '/globalSettings', icon: <Settings />},
  {label: 'Users', url: '/users', icon: <People />},
  {label: 'Plans', url: '/plans', icon: <MonetizationOn />},
  {label: 'Transactions', url: '/transactions', icon: <Money />},
  {label: 'Scripts', url: '/scripts', icon: <CodeSharp />},
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    'color': 'inherit',
    'fontSize': theme.typography.fontSize,
    '&$textDense': {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const history = useHistory();

  const {classes, ...other} = props;
  const logoutUser = () => {
    logout();
    history.push('/login');
  };
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.item,
            classes.itemCategory,
          )}
        >
          Vdocipher
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            size="small"
            onClick={logoutUser}
          >
            Logout
          </Button>
        </ListItem>
        {list.map((item, id) => (
          <ListItemLink
            key={id}
            primary={item.label}
            to={item.url}
            icon={item.icon}
          />
        ))}
      </List>
    </Drawer>
  );
}

// copied from: https://material-ui.com/guides/composition/#list
const ListItemLink = ({icon, primary, to}) => {
  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? (
          <ListItemIcon style={{color: 'white'}}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          primary={primary}
          primaryTypographyProps={{style: {color: 'white'}}}
          color={'primary'}
        />
      </ListItem>
    </li>
  );
};

export default withStyles(styles)(Navigator);
