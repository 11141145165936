import {authRequest} from '../utils/api';

const fetchUserList = (searchQuery) => {
  return authRequest({
    url: '/cms/users',
    method: 'POST',
    data: {
      searchQuery,
    },
  });
};

export const userListServices = {
  fetchUserList,
};
