import {blogsConstants} from '../constants';

const initialState = {
  errorMessage: null,
  loggedIn: false,
  pages: [],
  page: null,
};
const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case blogsConstants.FETCH_PAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogsConstants.FETCH_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.pages,
        loading: false,
      };
    case blogsConstants.FETCH_PAGES_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };

    case blogsConstants.FETCH_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogsConstants.FETCH_PAGE_SUCCESS:
      return {
        ...state,
        page: action.page,
        loading: false,
      };
    case blogsConstants.FETCH_PAGE_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };

    case blogsConstants.CREATE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogsConstants.CREATE_PAGE_SUCCESS:
      return {
        ...state,
        page: null,
        loading: false,
      };
    case blogsConstants.CREATE_PAGE_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };

    case blogsConstants.UPDATE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogsConstants.UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        page: null,
        loading: false,
      };
    case blogsConstants.UPDATE_PAGE_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };

    case blogsConstants.DELETE_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogsConstants.DELETE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogsConstants.DELTE_PAGE_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
        page: null,
      };
    default:
      return state;
  }
};

export default blogsReducer;
