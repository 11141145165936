import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const MainProject = ({project, deleteSetting}) => {
  return (
    <Table>
      <TableBody>
        {Object.keys(project).map((info, index) => (
          <TableRow key={index}>
            <TableCell align="right">{info}</TableCell>
            <TableCell align="right">
              {info === 'hotjarids'
                ? ''
                : ['registrationTime', 'lastLogin'].includes(info)
                ? new Date(project[info]).toLocaleString()
                : project[info]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MainProject;
