import {combineReducers} from 'redux';
import users from './users.reducer';
import blogs from './blogs.reducer';
import transactions from './transactions.reducer';
import userList from './userList.reducer';
import globalSettings from './globalSettings.reducer';

const rootReducer = combineReducers({
  users,
  blogs,
  transactions,
  userList,
  globalSettings,
});

export default rootReducer;
